import { styled } from '@linaria/react';

export const Button = styled.button`
  display: flex;
  align-items: center;
  padding: var(--spacing-2xs) var(--spacing-sm) var(--spacing-2xs) var(--spacing-xs);
  gap: var(--spacing-xs);
  border-radius: var(--radius-full);
  color: var(--primary);
  font-family: var(--font-sans);
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  width: fit-content;

  :hover {
    background-color: var(--darken-neutral-hover);
  }

  :active {
    background-color: var(--darken-neutral-press);
  }
`;
