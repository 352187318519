import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button } from './RMBackButton.styles';
export interface RMBackButtonProps {
  /**
   * The text displayed in the back button
   */
  label?: string;
  /**
   * The callback triggered when the user clicks on the back button
   */
  onClick?: () => void;
}

/**
 * Renders a back button
 */
export function RMBackButton({ label = 'Back', onClick }: RMBackButtonProps) {
  return (
    <Button onClick={onClick}>
      <FontAwesomeIcon icon={faChevronLeft} size="sm" />
      {label}
    </Button>
  );
}
